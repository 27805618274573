import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Overhead Squats 5×2\\@90% 1RM`}</p>
    <p>{`Snatch Grip RDL 5×4\\@90% Snatch Max`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`25/20 Calorie Assault Bike`}</p>
    <p>{`15 Power Snatch (95/65)(RX+ 115/75)`}</p>
    <p>{`10 OHS (95/65)(RX+ 115/75)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      